@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

html,
body {
  overscroll-behavior: none;
}



body {
  font-family: 'Ubuntu', sans-serif;
  font-size: 11pt;
}

.rs-panel-header {
  font-family: 'Ubuntu', sans-serif;
}

.rs-panel-header.rs-heading {
  padding: 0;

}

.rs-panel {
  background-color: white;
  padding: 0;

}

.rs-sidenav-item {
  background-color: transparent !important;

}

.rs-sidenav-nav {
  border-top-right-radius: 20px !important;
  background-color: white;
}

.menu-items {
  @apply flex flex-row items-center gap-2 text-base cursor-pointer hover:text-violet-900 hover:font-semibold text-gray-600 font-[300] text-[12pt]
}

.menu-items-active {
  @apply flex flex-row items-center gap-2 text-base cursor-pointer text-violet-900 font-[500] text-[12pt]
}

.rs-dropdown-toggle, .rs-dropdown-toggle.rs-btn {
  @apply bg-white
}

.swatches-picker>div {
  width: 600px;

}

.swatches-picker>div>div {
  box-shadow: none !important;
}

.custom-menu button {
  padding: 0 !important;

}

.custom-menu .rs-dropdown-toggle,
.custom-menu .rs-dropdown-toggle.rs-btn {
  background-color: initial;
}

.custom-menu button {
  background-color: initial;
}

.custom-menu button:hover {
  background-color: initial;
}

.react-resizable-handle-e {}

.react-resizable-handle {
  background-image: none !important;
}

.cell-resizable {
  @apply border-t border-b border-r justify-center h-10 py-1 px-2 flex items-center truncate text-ellipsis font-[500]
}

.cell-resizable-footer {
  @apply border-b border-r justify-center h-10 py-1 px-2 flex items-center truncate text-ellipsis font-[500]
}

.cell-body {
  @apply h-10 py-1 px-2 flex items-center truncate text-ellipsis text-[10pt]
}

.cell-resizable .cell-resizable-bottom {
  @apply border-t-0
}

.rbc-addons-dnd {
  @apply text-[11pt]
}

textarea {}

.context-menu {
  position: fixed;
  width: 200px;
  background-color: #383838;
  border-radius: 5px;
  box-sizing: border-box;
  z-index: 9999;
}

.ul-context-menu {
  box-sizing: border-box;
  padding: 10px;
  margin: 0;
  list-style: none;
}

.ul-context-menu li {
  padding: 18px 12px;
}

/* hover */
.ul-context-menu li:hover {
  cursor: pointer;
  background-color: #000000;
}

.rs-custom-date .rs-input-group.rs-input-group-inside {
  border-color: white !important;
  outline: none;
  outline-color: white !important;
}

.rs-custom-date .rs-input::placeholder {
  color: #dedede;
  opacity: 1;
  /* Firefox */
}

.rs-custom-date .rs-input-group-addon {
  display: none;
}

.rs-custom-select .rs-picker-toggle-indicator {

  display: none;
}

.rs-custom-select .rs-btn {
  background-color: transparent !important;
  color: white;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the arrows for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.custom-progress .rs-progress-circle-info {
  @apply text-[8pt] flex justify-center items-center
}

.custom-drawer {
  padding: 10px 20px !important;
}


.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #555 !important;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-focus,
.rs-picker-select-menu-item:focus,
.rs-picker-select-menu-item:hover {
  color: #555 !important;
}

.cell-table {
  @apply border-b border-r h-10 py-1 px-2 flex items-center truncate text-ellipsis text-[10pt]
}

.cell-table-header {
  @apply border-t border-b border-r justify-center h-10 py-1 px-2 flex items-center truncate text-ellipsis font-[500]
}

.custom-tag:focus {
  outline: none !important;
  outline-color: white !important;
  border-color: white !important;
}

.custom-tag {
  outline: none;
  border-color: white !important;
  width: 100%;
}

.custom-tag .rs-picker-toggle-indicator {
  display: none;
}

.rs-picker:focus {
  border-color: white !important;
  outline-color: white !important;
  outline: none;


}

.custom-tag2 {
  background-color: white !important;
  padding: 0 !important;
  margin-top: 10px !important;
}

.custom-modal .rs-modal-body .rs-btn-subtle {
  display: none !important;
}

.ql-toolbar.ql-snow {
  @apply rounded-t-lg !border-gray-200 !border-[0.5px]
}

.ql-container.ql-snow {
  @apply rounded-b-lg !border-gray-200 !border-[0.5px] min-h-[200px] font-ubuntu
}

.ql-picker-label, .ql-picker-item {
  @apply font-ubuntu
}

.comment-wrapper {
  height: 64px;
  transition: height 0.10s ease-out;
}

.comment-wrapper-active {
  height: 160px;
  transition: height 0.10s ease-in;
}

.comment-input {
  height: 40px;
  transition: height 0.10s ease-out;
}

.comment-input-active {
  height: 80px;
  transition: height 0.10s ease-in;
}




.comment-section {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;

}

.comment {
  display: flex;
  margin-bottom: 15px;
}

.avatar img {
  margin-top: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.comment-content:hover {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

}

.comment-content {
  margin-left: 10px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  max-width: 80%;
}

.comment-header {
  margin-bottom: 5px;
}

.commenter-name {
  font-weight: bold;
  color: #4267B2;
  margin-right: 10px;
}

.comment-time {
  color: #777;
  font-size: 12px;
}

.comment-body {
  font-size: 14px;
  color: #333;
}

.comment:hover .comment-body {
  /* background-color: #e9ecef; */
}


.mention-member {
  color: #007bff;
  font-weight: bold;
}

.mention-client {
  color: #ff5733;
  font-weight: bold;
}

.comment-body a:hover {
  text-decoration: underline;
}

.ql-tooltip {
  left: 300px !important;
}

.react-resizable {
  position: relative;
  display: inline-block;

}

input:disabled,
.rs-input-group.rs-input-group-disabled {
  background-color: transparent !important;
  color: #000;
  /* Optional: Set text color for better contrast */
  opacity: 1;
  /* Ensure the background color is not faded */
}

.quill-preview .ql-container {
  border: white !important;
  min-height: initial;
}


.rs-picker-disabled {
  background-color: transparent !important;
}

.rs-toggle-input {
  visibility: hidden;
}

.rs-drawer-wrapper .rs-modal-sm .rs-modal-body-close {
  visibility: hidden !important;
}

 div > div > div.rs-modal-body > button {
  display: none !important;
}